const commEditorMixin = {
  data () {
    return {
      defaultEditorConfig: {
        height: '500px',
        initialEditType: 'wysiwyg',
        previewStyle: 'vertical'
      },
      editorOptions: {
        minHeight: '200px',
        language: 'en-US',
        useCommandShortcut: true,
        useDefaultHTMLSanitizer: true,
        usageStatistics: true,
        hideModeSwitch: false,
        toolbarItems: [
          'heading',
          'bold',
          'italic',
          'strike',
          'divider',
          'hr',
          'quote',
          'divider',
          'ul',
          'ol',
          'task',
          'indent',
          'outdent',
          'divider',
          'table',
          'image',
          'link',
          'divider',
          'code',
          'codeblock'
        ],
        customHTMLRenderer: {
          softbreak(_, { options }) {
            return {
              type: 'html',
              content: options.softbreak
            };
          }
        },
      }
    }
  },
  methods: {
    getEditorContents() {
      let contents;
      if (this.$refs.tuiEditor.invoke("isWysiwygMode")) {
        contents = this.$refs.tuiEditor.invoke("getHtml");
      } else if (this.$refs.tuiEditor.invoke("isMarkdownMode")) {
        contents = this.$refs.tuiEditor.invoke("getMarkdown");
      }
      return contents;
    }
  }
}

export default commEditorMixin
