<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">공지사항</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">공지사항 상세</h3>
            <div class="float-right">
              <base-button type="danger" @click="goBack()">목록</base-button>
            </div>
          </template>
          <template>
            <form role="form" class="needs-validation">
              <div class="form-group row align-items-center">
                <label class="col-md-2 col-form-label form-control-label text-center">공지 제목</label>
                <div class="col-md-10">
                  <h4 class="mb-0">{{ subject }}</h4>
                </div>
              </div>
              <div class="form-group row align-items-center" v-if="isDataLoad">
                <label class="col-md-2 col-form-label form-control-label text-center">내용</label>
                <div class="col-md-10">
                  <div class="card-wrapper">
                    <card class="mb-0">
                      <viewer id="viewer" ref="tuiViewer"
                              :height="defaultEditorConfig.height"
                              :initialValue="body"
                      />
                    </card>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0 align-items-center">
                <label class="col-md-2 col-form-label form-control-label text-center">첨부파일</label>
                <div class="col-md-10">
                  <p v-for="(item) in fileList" :key="item.fileSeq" class="mb-0">
                    <i class="fas" :class="_getFileIconByExt(item.fileExt)" style="font-size: 0.9em;"></i><span @click="handlePreview(item)" class="ml-1 font-weight-bold" style="font-size: 0.9em; color: #5e72e4; cursor: pointer;">{{ _getFileInfoText(item) }}</span>
                  </p>
                </div>
              </div>
            </form>
          </template>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import {Table, TableColumn, Select, Option} from 'element-ui';
  import { USER_API_PREFIX, RESULT_CODE } from '@/shared/util/const';
  import commEditorMixin from '@/shared/mixins/commEditorMixin';
  import commJsUtilMixin from '@/shared/mixins/commJsUtil';
  import commValidatorMixin from '@/shared/mixins/commValidatorMixin';

  export default {
    name: 'NoticeModify',
    mixins: [
      commEditorMixin ,commJsUtilMixin, commValidatorMixin
    ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      commEditorMixin
    },
    filters: {
    },
    created() {
      this.referer = this.$route.query.referer;
      if(this.$route.query.referer) {
        this.referer = this.$route.query.referer+'?page='+this.$route.query.page+'&perPage='+this.$route.query.perPage+'&keyword='+this.$route.query.keyword;
      }
      this.noticeDetail();
    },
    data() {
      return {
        noticeSeq: this.$route.params.seq,
        subject: '' ,
        body: '',
        fileUuid: null,
        isDataLoad: false,
        referer: '',
        fileList: []
      };
    },
    methods: {
      /**
       * 공지 정보 조회
       */
      noticeDetail() {
        this.$axios.post(`${USER_API_PREFIX}/board/notice-info`, {
          noticeSeq : this.noticeSeq
        }).then(res=> {
          if(res.data.result.code === RESULT_CODE.SUCCESS) {
              if(null != res.data.result.fileList) {
                this.fileList = res.data.result.fileList;
              }
              this.subject = res.data.result.info.subject;
              this.body = res.data.result.info.body;
              this.fileUuid = res.data.result.info.fileUuid;
              this.isDataLoad = true;
          }else {
            this.notify('warning', res.data.result.message);
            this.goBack();
          }
        }).catch((error) => {
          console.log(error)
        });
      },
      handlePreview(file) {
        this.download(file.fileSeq)
      },
      download(fileSeq) {
        this.$axios.get(`${USER_API_PREFIX}/files/download/${fileSeq}`, {
          responseType: 'blob'
        }).then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          const contentDisposition = res.headers['content-disposition'];

          let fileName = 'unknown';
          if (contentDisposition) {
            const [ fileNameMatch ] = contentDisposition.split(';').filter(str => str.includes('filename'));
            if(fileNameMatch) [ , fileName ] = fileNameMatch.split('=');
          }
          fileName = decodeURI(fileName).replace(/"/gi, '')

          link.href = url;
          link.setAttribute('download', `${fileName}`);
          link.click();
          window.URL.revokeObjectURL(url);
        }).catch((error) => {
          console.log(error)
        });
      },
      /**
       * 알림 메시지 생성
       * @param type
       */
      notify(type = 'default', message) {
        let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
        this.$notify({
          message: notiMsg,
          timeout: 5000,
          icon: 'ni ni-bell-55',
          type
        });
      },
      /**
       * 전 페이지로 이동
       */
      goBack() {
        this.$router.push(this.referer);
      }
    }
  };
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
